<template>
  <div class="app common-content">
    <Banner :BannerImg="BannerImg"></Banner>
    <div class="clearfix" v-if="contArr.length > 0">
      <div class="common-title">
        <p>产品中心</p>
        <span></span>
      </div>
      <el-row :gutter="20" class="content-cont">
        <el-col :span="8" v-for="(item, index) in contArr" :key="index">
          <template v-if="item">
            <div class="content-cont-list">
              <router-link active-class="news-item" :to="{name: 'Articles', query: { tag_id: item.id }}" target="_blank">
                <img :src="item.tag_img || ErrorImg" alt="">
                <p>{{item.tag_name}}</p>
              </router-link>
            </div>
          </template>
          <!--<template v-if="item.subs">-->
            <!--<div class="content-cont-list" v-for="(list, inx) in item.subs" :key="inx">-->
              <!--<router-link active-class="news-item" :to="index === 0 ? {name: 'Talent'} : (index === 1 ? {name: 'Financial'} : {name: 'Cpractice'})" target="_blank">-->
                <!--<img :src="list.tag_img || ErrorImg" alt="">-->
                <!--<p>{{list.tag_name}}</p>-->
              <!--</router-link>-->
            <!--</div>-->
          <!--</template>-->
        </el-col>
      </el-row>
    </div>
    <div class="clearfix">
      <div class="common-title">
        <p>课程价值</p>
        <span></span>
      </div>
      <el-row :gutter="5" class="content-wrap nobg">
        <el-col :span="8">
          <div class="border">
            <ul>
              <li class="radius"><i class="iconfont icon-huaban37"></i></li>
              <li>对个人价值</li>
              <li class="left">学习与掌握职业知识技能可以形成新时代下复合型人才的竞争壁垒，拥有良好的就业和薪资前景。</li>
            </ul>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="border">
            <ul>
              <li class="radius"><i class="iconfont icon-danwei"></i></li>
              <li>对企业价值</li>
              <li class="left">对企业发展而言，为企业培养专业技术人员，有助于企业优化经营管理，提高企业效率和服务质量。</li>
            </ul>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="border">
            <ul>
              <li class="radius"><i class="iconfont icon-yejiguanli"></i></li>
              <li>对社会价值</li>
              <li class="left">对社会发展而言，发展职业教育、培养技术密集型人才是推动创业创新和工业化发展的重要力量。</li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="5" class="content-wrap">
        <el-col :span="4">&nbsp;</el-col>
        <el-col :span="4">
          <svg class="icon myIconStyle" aria-hidden="true">
            <use xlink:href="#icon-ziyuan3"></use>
          </svg>
          <h3>运营成本降低</h3>
          <p>Reduce Cost</p>
        </el-col>
        <el-col :span="4">
          <svg class="icon myIconStyle" aria-hidden="true">
            <use xlink:href="#icon-ziyuan4"></use>
          </svg>
          <h3>盈利空间提升</h3>
          <p>Improve Profit</p>
        </el-col>
        <el-col :span="4">
          <svg class="icon myIconStyle" aria-hidden="true">
            <use xlink:href="#icon-ziyuan5"></use>
          </svg>
          <h3>有效管控风险</h3>
          <p>Control Risk</p>
        </el-col>
        <el-col :span="4">
          <svg class="icon myIconStyle" aria-hidden="true">
            <use xlink:href="#icon-ziyuan6"></use>
          </svg>
          <h3>提升市场占比</h3>
          <p>Increase Market Share</p>
        </el-col>
      </el-row> -->
    </div>
    <!-- <div class="clearfix" v-if="cases.length > 0">
      <div class="common-title">
        <p>产品案例</p>
        <span></span>
      </div>
      <div class="content-case">
        <el-row :gutter="20" class="content-case">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="(item, index) in cases" :key="index">
            <router-link active-class="" :to="{name: 'Productdetail', params: {id: item.id}}" target="_blank">
              <div class="case-num"><span>{{index + 1}}</span></div>
              <div class="case-text">
                <h3>{{item.title}}</h3>
                <hr>
                <p>{{item.digest}}</p>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div> -->
  </div>
</template>
<script>
import Banner from '../components/Banner.vue'
import BannerImg from '../assets/images/product_bg.jpg'
import ErrorImg from '../assets/images/rencai_10.jpg'
export default {
  components: {
    Banner
  },
  data () {
    return {
      BannerImg: BannerImg,
      ErrorImg: ErrorImg,
      cases: [],
      contArr: []
    }
  },
  methods: {
    getProduceCase () {
      this.$store.dispatch('getProduceCase').then(res => {
        this.cases = res.data
      })
    },
    getProduct () {
      this.$store.dispatch('getProduct').then(res => {
        this.contArr = res
      })
    }
  },
  mounted () {
    this.getProduceCase()
    this.getProduct()
  }
}
</script>
<style lang="less">
.icon {
  width: 80%;
  height: 6.5rem;
}
.app {
  .content-cont {
    margin: 0 !important;
    .content-cont-list {
      position: relative;
      margin-bottom: 1rem;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      p {
        margin: 0;
        width: 100%;
        height: 3.12rem;
        line-height: 3.12rem;
        text-align: center;
        background: @global-active-text-color;
        position: absolute;
        bottom: 0;
        color: @global-white;
        opacity: .8;
      }
    }
  }
  .content-wrap {
    width: 100%;
    background: url("../assets/images/chanpin_27.jpg") no-repeat;
    background-size: cover;
    padding: 3rem 0;
    color: @global-white;
    .el-col {
      border-right: 1px solid @global-white;
      &:first-child {
        border: none;
      }
      &:nth-child(2) {
        border-left: 1px solid @global-white;
      }
      p {
        .textwrap;
      }
    }
    &.nobg{
      background:none;
    }
    .border{
      width:90%; margin:0 auto;
      padding:10px;
      border:1px solid #c0c0c0;
      min-height: 220px;
      ul{
        margin:0;
        padding:0;
      }
      ul li{
        list-style: none;
        color:#42a2e7;
        text-align:center;
        font-size:18px;
        font-weight: bold;
        margin:0;
        padding:0;
        &.radius{
          background:#f5f5f5;
          width: 100px;
          height: 100px;
          border-radius: 100%;
          line-height: 100px;
          display: block;
          margin:15px auto;
          i{
            font-size:50px;
            font-weight: normal;
          }
          .icon-yejiguanli{
            font-size:60px;
          }
        }
      }
      .left{
        color:#666;
        text-align: left;
        line-height: 2.1;
        font-size:14px;
        font-weight: normal;
        padding-top:20px;
        text-indent: 30px;
      }
    }
  }
  .content-case {
    margin: 0 !important;
    .el-col {
      text-align: left;
      a {
        .flex;
        .case-num {
          width: 100px;
          height: 100px;
          transform: rotate(45deg);
          border: 1px solid @global-head-active-color;
          margin-right: 40px;
          span {
            display: block;
            transform: rotate(-45deg);
            font-size: 3rem;
            margin-left: 30%;
            color: @global-head-active-color;
          }
        }
        .case-text {
          width: 50%;
          h3 {
            margin: 0;
            color: @global-black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
          hr {
            width: 1rem;
            height: 3px;
            background: #efce2b;
            margin: 0;
            border: none;
            background: @global-head-active-color;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1101px) {
  .content-cont {
    .content-cont-list {
      height: 19rem;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1100px) {
  .content-cont {
    .content-cont-list {
      height: 17rem;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .content-cont {
    .content-cont-list {
      height: 15rem;
    }
  }
}
@media screen and (min-width: 540px) and (max-width: 768px) {
  .content-cont {
    .content-cont-list {
      height: 11rem;
      p {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
      }
    }
  }
}
@media screen and (max-width: 539px) {
  .content-cont {
      .content-cont-list {
        height: 6rem;
        p {
          height: 1rem !important;
          line-height: 1rem !important;
        }
      }
    }
  .icon {
    width: 80%;
    height: 3.5rem;
  }
  .content-wrap {
    h3 {
      margin: 0;
    }
  }
}
</style>
